
import {defineComponent, onMounted, ref, watch} from 'vue'
import store from "@/store";
import ActivityDocument from "@/views/activity/ActivityDocument.vue";
import Audits from "@/views/audit/Audits.vue";
import Notes from "@/views/note/Notes.vue";
import ActivityService from "@/core/services/ActivityService";
import DocumentTranscribe from "@/views/document/DocumentTranscribe.vue";
import Translate from "@/views/translate/Translate.vue";

export default defineComponent({
  name: "CallPanel",
  components: {Translate, DocumentTranscribe, Notes, Audits, ActivityDocument},
  props: {
    id: {type: String},
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = async (id) => {
      return await ActivityService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
    }
  }
})
