
import { defineComponent, onMounted, ref, watch } from "vue";
import UserService from "@/core/services/UserService";
import store from "@/store";
import RecentLogin from "@/views/user/RecentLogin.vue";
import UserActionLog from "@/views/actionView/UserActionLog.vue";
import UserPanelTeam from "@/views/user/UserPanelTeam.vue";

export default defineComponent({
  name: "UserPanel",
  components: {UserPanelTeam, UserActionLog, RecentLogin},
  props: {
    id: {
      type: String,
    }
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = async (id) => {
      return await UserService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data
    }
  }
})
