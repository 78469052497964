
import {computed, defineComponent, nextTick, onBeforeMount, onMounted, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTLoader from "@/components/Loader.vue";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {MenuComponent} from "@/assets/ts/components";
import {reinitializeComponents} from "@/core/plugins/keenthemes";
import {removeModalBackdrop} from "@/core/helpers/dom";
import {
  asideEnabled,
  contentWidthFluid,
  loaderEnabled,
  loaderLogo,
  subheaderDisplay,
  themeDarkLogo,
  themeLightLogo,
  toolbarDisplay,
} from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
import {IBreadcrumbs} from "@/core/helpers/breadcrumb";
import Panel from "@/components/base/panel/Panel.vue";
import ClaimPanel from "@/views/claim/ClaimPanel.vue";
import PolicyPanel from "@/views/policy/PolicyPanel.vue";
import CasePanel from "@/views/case/CasePanel.vue";
import CompanyPanel from "@/views/company/CompanyPanel.vue";
import InsuredPanel from "@/views/insured/InsuredPanel.vue";
import UserPanel from "@/views/user/UserPanel.vue";
import ProviderPanel from "@/views/provider/ProviderPanel.vue";
import ProductPanel from "@/views/product/ProductPanel.vue";
import InvoicePanel from "@/views/invoice/InvoicePanel.vue";
import DocumentPanel from "@/views/document/DocumentPanel.vue";
import CasePreview from "@/views/claim/CasePreview.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import TemplatePanel from "@/views/template/TemplatePanel.vue";
import ReservePanel from "@/views/reserve/ReservePanel.vue";
import AuditPanel from "@/views/audit/AuditPanel.vue";
import PaymentPanel from "@/views/payment/PaymentPanel.vue";
import ActivityPanel from "@/views/activity/ActivityPanel.vue";
import CheckListPanel from "@/views/checklist/CheckListPanel.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import PolicyService from "@/core/services/PolicyService";
import CaseService from "@/core/services/CaseService";
import ClaimService from "@/core/services/ClaimService";
import InvoiceService from "@/core/services/InvoiceService";
import PaymentGroupPanel from "@/views/payment/PaymentGroupPanel.vue";
import ActionViewPanel from "@/views/actionView/ActionViewPanel.vue";
import CaseProvider from "@/views/provider/CaseProvider.vue";
import FormTask from "@/views/task/FormTask.vue";
import TaskPanel from "@/views/task/TaskPanel.vue";
import DocPanel from "@/views/page/DocPanel.vue";
import PolicyRemotePanel from "@/views/policy/PolicyRemotePanel.vue";
import Tags from "@/views/tag/Tags.vue";
import {Client} from "@stomp/stompjs";
import GenericProcessForm from "@/views/process/GenericProcessForm.vue";
import {PROCESS_TYPE} from "@/core/config/Constant";
import ServicePanel from "@/views/service/ServicePanel.vue";
import BHSIRemotePanel from "@/views/policy/BHSIRemotePanel.vue";
import CustomFormPanel from "@/views/custome-form/CustomFormPanel.vue";
import ReminderPanel from "@/views/reminder/ReminderPanel.vue";
import ReminderForm from "@/views/reminder/ReminderForm.vue";
import CallPanel from "@/views/assistance/call-center/CallPanel.vue";
import SanctionCheckPanel from "@/views/sanction-check/SanctionCheckPanel.vue";
import EmailPanel from "@/views/email/EmailPanel.vue";
import DocumentContentPanel from "@/views/document/DocumentContentPanel.vue";

export default defineComponent({
  name: "Layout",
  components: {
    DocumentContentPanel,
    EmailPanel,
    SanctionCheckPanel,
    CallPanel,
    ReminderForm,
    BHSIRemotePanel,
    ReminderPanel,
    CustomFormPanel,
    ServicePanel,
    GenericProcessForm,
    Tags,
    PolicyRemotePanel,
    DocPanel,
    TaskPanel,
    FormTask,
    CaseProvider,
    ActionViewPanel,
    PaymentGroupPanel,
    BaseModal,
    CheckListPanel,
    ActivityPanel,
    PaymentPanel,
    AuditPanel,
    ReservePanel,
    TemplatePanel,
    EntityLoading,
    CasePreview,
    DocumentPanel,
    InvoicePanel,
    ProductPanel,
    ProviderPanel,
    UserPanel,
    InsuredPanel,
    CompanyPanel,
    CasePanel,
    PolicyPanel,
    ClaimPanel,
    Panel,
    KTAside,
    KTHeader,
    KTFooter,
    KTScrollTop,
    KTLoader,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    ApiService.setHeader();
    const baseModalDeleteEntity = ref();
    const genericTaskRef = ref()
    const baseTagsRef = ref()
    const processFormRef = ref()
    const deleteReason = ref({deleteReason: ''})
    const deleting = ref(false);
    onBeforeMount(async () => {

      if (!store.getters.isUserAuthenticated) {
        await router.push({name: "sign-in"});
      }
      await store.dispatch('LOAD_THEME');
      if (store.getters.isUserAuthenticated) {
        store.commit(Mutations.WS_CONNECT)
        await store.dispatch('GET_IMPERSONAL')
        await store.dispatch(Actions.LOAD_EXCHANGE_RATES);
        await store.dispatch(Actions.LOAD_CURRENCIES);
        await store.dispatch(Actions.LOAD_COUNTRY)
        await store.dispatch(Actions.LOAD_STATE)
      }
    })
    // show page loading
    store.dispatch(Actions.LOAD_CHAT_CONTACTS);
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    const user = computed(() => store.getters.currentUser)

    const client = computed<Client>(() => store.state.WebSocketModule.client);
    const panelData = computed(() => store.state.PanelModule.panelData);
    const baseModal = computed(() => store.state.PanelModule.baseModal);
    const tagModal = computed(() => store.state.PanelModule.tagModal);
    const processModal = computed(() => store.state.PanelModule.processModal);
    const genericTaskModal = computed(() => store.state.PanelModule.genericTaskModal);
    const contacts = computed(() => store.state.ChatModule.contacts);
    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.state.BreadcrumbsModule.title;
    });

    const background = computed(() => store.state.UserModule.background);
    const theme = computed(() => store.state.UserModule.theme);
    const state = reactive({
      styles: 'background-image:url(' + background.value + ');background-color: ' + theme.value,
    });

    watch(() => baseModal.value.isShow, (cb) => {
      if (cb === true) {
        baseModalDeleteEntity.value.showBaseModal();
      } else {
        baseModalDeleteEntity.value.hideBaseModal();
      }
    });
    watch(() => tagModal.value.show, (cb) => {
      if (cb === true) {
        baseTagsRef.value.showBaseModal();
      } else {
        baseTagsRef.value.hideBaseModal();
      }
    });

    watch(() => processModal.value.show, (cb) => {
      if (cb === true) {
        processFormRef.value.showBaseModal();
      } else {
        processFormRef.value.hideBaseModal();
      }
    });

    watch(() => genericTaskModal.value.isShow, (cb) => {
      if (cb) {
        genericTaskRef.value.showBaseModal();
      } else {
        genericTaskRef.value.hideBaseModal();
      }
    });

    watch(() => background.value, (cb) => {
      if (cb) {
        state.styles = 'background-image:url(' + cb + ');background-color: ' + theme.value;
      } else {
        state.styles = 'background-image:url();;background-color: ' + theme.value;
      }
    })

    watch(() => theme.value, (cb) => {
      state.styles = 'background-image:url(' + background.value + ');background-color: ' + cb;
    })

    const breadcrumbs = computed<IBreadcrumbs[]>(() => {
      return store.state.BreadcrumbsModule.objectBreadcrumbs;
    });

    const closePanel = () => {
      store.commit('SET_PANEL_STATUS', false)
    }

    const hideBaseModal = () => {
      store.commit('SET_MODAL_STATUS', false);
    }

    const hideTagModal = () => {
      store.commit('SET_HIDE_TAG_MODAL');
    }
    const hideProcessModal = () => {
      store.commit('SET_HIDE_PROCESS_MODAL');
    }
    const hideGenericTaskModal = () => {
      store.commit('SET_GENERIC_MODAL', false);
    }
    onMounted(() => {

      if (!store.getters.isUserAuthenticated) {
        router.push({name: "sign-in"});
      }

      nextTick(() => {
        reinitializeComponents();
      });


      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);

      userNotificationSub();
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);
        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({name: "sign-in"});
        }
        closePanel();
        nextTick(() => {
          reinitializeComponents();
        });
        removeModalBackdrop();
      }
    );

    const onDeleteEntity = () => {
      deleting.value = true;
      const id = baseModal.value.objectId;
      const objectType = baseModal.value.type;
      switch (objectType) {
        case 'POLICY':
          PolicyService.deleteReason(id, deleteReason.value).then(() => {
            hideBaseModal();
            router.push({name: "policies"});
          }).finally(() => {
            deleting.value = false;
            deleteReason.value.deleteReason = '';
          })
          break
        case 'CASE':
          CaseService.deleteReason(id, deleteReason.value).then(() => {
            hideBaseModal();
            router.push({name: "cases"});
          }).finally(() => {
            deleting.value = false;
            deleteReason.value.deleteReason = '';
          })
          break
        case 'CLAIM':
          ClaimService.deleteReason(id, deleteReason.value).then(() => {
            hideBaseModal();
            router.push({name: "claims"});
          }).finally(() => {
            deleting.value = false;
            deleteReason.value.deleteReason = '';
          })
          break
        case 'INVOICE':
          InvoiceService.deleteReason(id, deleteReason.value).then(res => {
            hideBaseModal();
            router.push({name: "claim", params: {id: res.claim.id}});
          }).finally(() => {
            deleting.value = false;
            deleteReason.value.deleteReason = '';
          })
          break
      }
    }

    const userNotificationSub = () => {
      if (client.value && client.value.connected) {
        client.value.subscribe(`/topic/public/user-status`, (data) => {
          const mess = JSON.parse(data.body)
          const contact = contacts.value.find(item => item.name == mess.email)
          if (contact) {
            store.commit(Mutations.SET_CONTACT_STATUS, {email: mess.email, online: mess.online})
            /*if (mess.online) {
              useToast().success(mess.message, {position: 'top-right'})
            }*/
          }
        })

        client.value.subscribe(`/user/topic/chat/read`, (data) => {
          const mess = JSON.parse(data.body)
          store.commit(Mutations.SET_CONTACT_READ, {id: mess.contactId, unread: mess.unread})
        })
      } else {
        setTimeout(userNotificationSub, 2000)
      }
    }


    // const activityInterval = 60 * 1000;


    // activity-tracker.js
    let lastActivityTime = Date.now();
    const pingInterval = 60000; // 1 minute in milliseconds
    let activityTimeout;
    const pingServer = () => {
      console.log("ping server")
      store.commit(Mutations.WS_SEND, {destination: '/app/auth/activity', data: {}})
      lastActivityTime = Date.now();
    };
    const resetActivityTimeout = () => {
      clearTimeout(activityTimeout);
      if (document.visibilityState === 'visible') {
        if (Date.now() - lastActivityTime >= pingInterval) {
          pingServer();
        }

      }
    };
    const updateLastActivityTime = () => {
      resetActivityTimeout();
    };
// Attach event listeners for user activity
    document.addEventListener('keypress', updateLastActivityTime);
    document.addEventListener('mousemove', updateLastActivityTime);
    document.addEventListener('mousedown', updateLastActivityTime);
// Handle tab visibility change
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        resetActivityTimeout();
      } else {
        clearTimeout(activityTimeout);
      }
    });
// Initial setup
    resetActivityTimeout();

    return {
      genericTaskModal,
      closePanel,
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      panelData,
      baseModal,
      state,
      baseModalDeleteEntity,
      deleteReason,
      hideBaseModal,
      deleting,
      onDeleteEntity,
      hideGenericTaskModal,
      genericTaskRef,
      baseTagsRef,
      processModal,
      processFormRef,
      user,
      hideTagModal,
      tagModal,
      client,
      PROCESS_TYPE,
      contacts,
      hideProcessModal,
    };
  },
  methods: {},

});
