import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "overflow-auto mb-3" }
const _hoisted_6 = { class: "align-items-center border border-dashed border-gray-300 rounded p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_translate = _resolveComponent("translate")!
  const _component_ActivityDocument = _resolveComponent("ActivityDocument")!
  const _component_Notes = _resolveComponent("Notes")!
  const _component_Audits = _resolveComponent("Audits")!
  const _component_DocumentTranscribe = _resolveComponent("DocumentTranscribe")!

  return (_ctx.data.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: `/phone-call/` + _ctx.data.id
            }, {
              default: _withCtx(() => [
                _createTextVNode(" [" + _toDisplayString(_ctx.data.type.name) + "] " + _toDisplayString(_ctx.data?.title ? _ctx.data.title : _ctx.data.type.name), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_translate, {
                body: _ctx.data.note,
                lang: _ctx.data.lang
              }, null, 8, ["body", "lang"])
            ])
          ]),
          _createVNode(_component_ActivityDocument, {
            activity: _ctx.data,
            ref: "activityDocumentRef"
          }, null, 8, ["activity"]),
          _createVNode(_component_Notes, {
            id: _ctx.data.id,
            type: 'ACTIVITY',
            ref: "refNotes"
          }, null, 8, ["id"]),
          _createVNode(_component_Audits, {
            "object-id": _ctx.data.id,
            "object-type": 'ACTIVITY',
            ref: "activityAuditRef"
          }, null, 8, ["object-id"]),
          _createVNode(_component_DocumentTranscribe, {
            id: _ctx.data.document
          }, null, 8, ["id"])
        ])
      ]))
    : _createCommentVNode("", true)
}