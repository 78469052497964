import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-gray-600 fs-6 fw-bold pt-3 mb-0",
  style: {"white-space":"pre-line"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!
  const _component_translate = _resolveComponent("translate")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_EntityLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.data.transcribe)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
              _createVNode(_component_translate, {
                body: _ctx.data.transcribe,
                lang: _ctx.data.lang
              }, null, 8, ["body", "lang"])
            ]))
          : _createCommentVNode("", true)
      ], 64))
}