
import {defineComponent, onMounted, ref, watch} from 'vue'
import store from "@/store";
import DocumentService from "@/core/services/DocumentService";
import Translate from "@/views/translate/Translate.vue";

export default defineComponent({
  name: "DocumentContentPanel",
  components: {Translate},
  // components: {},
  props: {
    id: {}
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = async (id) => {
      return await DocumentService.getContentUrl(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
    }
  }
})
