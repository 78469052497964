
import {defineComponent, ref} from 'vue'
import UserService from "@/core/services/UserService";

export default defineComponent({
  name: "UserPanelTeam",
  props: {
    userId: {type: String, required: true},
  },
  setup(props) {
    const teams = ref<any>([]);
    const loadTeams = (userId: string) => {
      UserService.getTeams(userId).then((data) => {
        teams.value = data;
      })
    }
    loadTeams(props.userId);
    return {
      teams,
    }
  }
})
