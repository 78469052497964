
import { defineComponent, computed } from "vue";
import { headerWidthFluid } from "@/core/helpers/config";
import { headerFixed, headerFixedOnMobile } from "@/core/helpers/config";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
    breadcrumbs: Array,
  },
  components: {
    // KTTopbar,
  },
  setup() {
    const {t}= useI18n()
    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value;
      } else {
        return headerFixedOnMobile.value;
      }
    });

    return {
      t,
      headerWidthFluid,
      isHeaderSticky,
    };
  },
});
