export const PolicyMenu = [
  {
    heading: "Case Management",
    pages: [
      {
        sectionTitle: "policy",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "search",
            route: "/policies",
          },
          {
            heading: 'recent',
            route: '/list-policy/recent'
          },
          {
            heading: 'watching',
            route: '/list-policy/watching'
          },
        ],
      },
      {
        sectionTitle: "cases",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        open: true,
        sub: [
          {
            heading: "search",
            route: "/cases",
          },
          {
            heading: "newCases",
            route: "/new-cases",
          },
          {
            heading: 'myCases',
            route: '/list-case/my-cases'
          },
          {
            heading: 'recent',
            route: '/list-case/recent'
          },
          {
            heading: 'watching',
            route: '/list-case/watching'
          },
        ],
      },
      {
        sectionTitle: "Claims",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        open: false,
        sub: [
          {
            heading: "search",
            route: "/claims",
          },
          {
            heading: 'NewClaims',
            route: '/new-claims'
          },
          {
            heading: 'FastTrack',
            route: '/claims-fast-track'
          },
          {
            heading: 'MyClaims',
            route: '/list-claim/my-claims'
          },
          {
            heading: 'recent',
            route: '/list-claim/recent'
          },
          {
            heading: 'watching',
            route: '/list-claim/watching'
          }
/*
          ,
          {
            heading: 'Deleted',
            route: '/list-claim/deleted-claims',
            adminOnly: true
          },
*/

        ],
      },
      {
        sectionTitle: "Invoices",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        open: false,
        sub: [
          {
            heading: "search",
            route: "/invoices",
          },
          {
            heading: 'NewInvoices',
            route: '/new-invoices'
          },
          {
            heading: 'MyInvoices',
            route: '/list-invoice/my-invoices'
          },
          {
            heading: 'recent',
            route: '/list-invoice/recent'
          },
          {
            heading: 'watching',
            route: '/list-invoice/watching'
          },
        ],
      },
      {
        sectionTitle: "Providers",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        open: false,
        sub: [
          {
            heading: "search",
            route: "/providers",
          },
        ],
      },
    ],
  },
]
export const CaseMenu = [
  {
    heading: "case",
    route: "/cases",
    pages: [
      {
        heading: "search",
        route: "/cases",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
        function: 'POLICY',
        action: 'VIEW'
      },
    ]
  }
]
export const Claims = [
  {
    heading: "Claims",
    pages: [
      {
        heading: "dashboard",
        route: "/claims-dashboard",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Reminders Due",
        route: "/claim-reminders-due",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Claim Review Due",
        route: "/claim-review-due",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Approval Requests",
        route: "/claim-approval-requests",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      }
      /*
            ,
            {
              heading: 'Pending Activities',
              route: '/claim/pending-activities',
              svgIcon: "",
              fontIcon: "bi-app-indicator",
            }
      */
    ],
  },
]

export const CaseManagement = [
  {
    heading: "Case Management",
    pages: [
      {
        sectionTitle: "CaseManagement",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "InpatientCases",
            route: "/case-management/cases",
          },
          {
            heading: 'Hospitalizations',
            route: '/case-management/hospitalizations'
          },
          {
            heading: 'OutpatientCases',
            route: "/case-management/outpatient-cases",
          }
        ],
      },
      {
        sectionTitle: "Claims",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "dashboard",
            route: "/claims-dashboard",
          },
          {
            heading: "NoExaminer",
            route: "/claims-no-examiners",
          },
          {
            heading: "DocsOverdue",
            route: "/claims-overdue-pending-documents",
          },

          {
            heading: "RemindersDue",
            route: "/claim-reminders-due"
          },
          // {
          //   heading: "Claim Review Due",
          //   route: "/claim-review-due"
          // },
          {
            heading: "ManagerApprovals",
            route: "/claim-approval-requests",
          },
          {
            heading: "ClientApprovals",
            route: "/claim-client-approvals",
          },
          {
            heading: "WaitingDecision",
            route: "/claims-ready-decision",
          },
        ],
      },
    ],
  },
]
export const Assistance = [
  {
    heading: "Assistance",
    pages: [
      {
        sectionTitle: "Call Center",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "dashboard",
            route: "/call-center/dashboard",
          },
          {
            heading: 'PhoneCalls',
            route: '/call-center/search'
          }
        ],
      },
      {
        sectionTitle: "Emails",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          // {
          //   heading: "Cases",
          //   route: "/case-management/outpatient-cases",
          // },
        ],
      },
    ],
  },
]
export const AdminMenu = [
  {
    heading: "Admin",
    pages: [
      {
        heading: "Content Mapping",
        route: "/content-mapping",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Quicksight Report",
        route: "/quicksight-report",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Action Templates",
        route: "/action-templates",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Action Groups",
        route: "/action-template-groups",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },


    ],
  },
]
export const ToolMenu = [
  {
    heading: "Tools",
    pages: [
      {
        heading: "Companies",
        route: "/list-company/clients",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Users",
        route: "/users",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Login Audit",
        route: "/login-audit",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Teams",
        route: "/teams",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Templates",
        route: "/templates",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Notifications",
        route: "/notifications",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Lookups",
        route: "/lookups",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Tags",
        route: "/tags",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
    ],

  },
]

export const Finance = [
  {
    heading: "Payments",
    pages: [
      {
        sectionTitle: "Payments",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "Search",
            route: "/payments",
          },
          {
            heading: 'List',
            route: '/list-payment/recent',
          },
          {
            heading: 'GroupPayments',
            route: '/group-payments',
          }
        ],
      },
      {
        heading: "ChequeRegister",
        route: "/reports/cheque_register",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "ClientFees",
        route: "/client-fees",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },

    ],
  },
]
export const Dashboard_Reports = [
  {
    heading: "Reports",
    pages: [
      {
        sectionTitle: "ClientDashboards",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "CallCenter",
            route: "/dashboard/call-center",
          },
          {
            heading: 'CaseManagement',
            route: '/dashboard/case-management',
          },
          {
            heading: 'Reserves',
            route: '/dashboard/reserves',
          },
          {
            heading: 'Claims',
            route: '/dashboard/claims',
          },
          {
            heading: 'CostContainment',
            route: '/dashboard/repricing',
          },
          {
            heading: 'Recovery',
            route: '/dashboard/recovery',
          },
          {
            heading: 'ReportsStorage',
            route: '/log-reports',
          },
        ],
      },
      {
        sectionTitle: "TridentDashboards",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: 'CaseManagement',
            route: '/dashboard/tr-case-management',
          },
          {
            heading: 'TimeReported',
            route: '/dashboard/tr-team-reported',
          },
          {
            heading: 'SystemTimeLog',
            route: '/dashboard/tr-team-system-time',
          }
        ],
      },
    ],
  },
]

export const Team = [
  {
    heading: "Team",
    pages: [
      {
        sectionTitle: "Team",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
/*
          {
            heading: "Dashboard",
            route: "/team/dashboard",
          },
*/
          {
            heading: 'ActionLog',
            route: '/team/action-log',
          },
          {
            heading: 'LoginAudit',
            route: '/login-audit',
          },

        ],
      },
    ],
  },
  {
    heading: "TasksReminders",
    pages: [
      {
        sectionTitle: "Tasks_Reminders",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          {
            heading: "MyTasks",
            route: "/my-tasks",
          },
          {
            heading: 'MyTeamTasks',
            route: '/team-tasks',
          },
          {
            heading: 'AllTasks',
            route: '/task-search',
          },
          {
            heading: "MyReminders",
            route: "/my-reminders",
          },
          {
            heading: 'AllReminders',
            route: '/reminder-search',
          },
        ],
      },

    ],
  },
  {
    heading: "Data Consistency",
    pages: [
      {
        sectionTitle: "DataConsistency",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: true,
        sub: [
          /*
                    {
                      heading: 'My Data Problems',
                      route: '',
                    },
          */
          {
            heading: 'All Data Problems',
            route: '/team/scans',
          },
        ],
      },

    ],
  },

  /*  {
      heading: "Reminders",
      pages: [
        {
          sectionTitle: "Reminders",
          svgIcon: "",
          fontIcon: "bi-archive",
          open: false,
          sub: [
            {
              heading: "My Reminders",
              route: "/my-reminders",
            },
            {
              heading: 'Search Reminders',
              route: '/reminder-search',
            },
          ],
        },
      ],
    },*/
  // {
  //   heading: "Actions",
  //   pages: [
  //     {
  //       sectionTitle: "Actions",
  //       svgIcon: "",
  //       fontIcon: "bi-archive",
  //       open: true,
  //       sub: [
  //         {
  //           heading: "COC",
  //           route: "/case-management/coc",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    heading: "Processes",
    pages: [
      {
        sectionTitle: "Processes",
        svgIcon: "",
        fontIcon: "bi-archive",
        open: false,
        sub: [
          {
            heading: "Medical Reviews",
            route: "/list-process/MEDICAL_REVIEW",
          },
          {
            heading: "Complaints",
            route: "/list-process/COMPLAINT",
          },
          {
            heading: "Inquiries",
            route: "/list-process/INQUIRY",
          },
          {
            heading: "Appeals",
            route: "/list-process/APPEAL",
          },
          {
            heading: "Issues",
            route: "/list-process/ISSUE",
          },
          {
            heading: "Escalation",
            route: "/list-process/ESCALATION",
          },
        ],
      },
      {
        heading: "Sanction Checks",
        route: "/sanction-checks",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
    ],
  },

]

export const CustomerMenu = [
  {
    heading: "Customer",
    pages: [
      {
        heading: "Inbox",
        route: "/customers/inbox",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "New Customers",
        route: "/customers/new",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Confirmed Customers",
        route: "/customers/confirmed",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Pending Claims",
        route: "/customers/pending-claims",
        svgIcon: "",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
]